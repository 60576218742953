import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { PageTitle } from '../../layout';
import { EditCompaniesPage } from './EditCompaniesPage';
import { EditPersonsPage } from './EditPersonsPage';

const TabContent = styled.div`
  padding-top: 20px;
`;

export const EditOwnersPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <PageTitle>Redigera ägare</PageTitle>
      <Tabs value={activeTab} onChange={onTabChange} aria-label="Ägare">
        <Tab label="Privatpersoner" />
        <Tab label="Företag" />
      </Tabs>

      {activeTab == 0 && (
        <TabContent>
          <EditPersonsPage />
        </TabContent>
      )}
      {activeTab == 1 && (
        <TabContent>
          <EditCompaniesPage />
        </TabContent>
      )}
    </>
  );
};
