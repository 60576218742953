import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import { ChevronDown, ChevronRight } from 'react-feather';
import { TreeNodeData } from './types';

type SelectCategoryTreeNodeProps = {
  node: TreeNodeData;
  onClick: (node: TreeNodeData) => void;
};
type NodeIconProps = {
  node: TreeNodeData;
};

type NodeWrapperProps = {
  depth: number;
};

const NodeTitle = styled.div``;

const NodeChildCount = styled.div`
  font-size: 0.8rem;
`;

const NodeWrapper = styled.div<NodeWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  border-radius: 0.25rem;
  padding-left: ${(props) => props.depth * 20 + 10}px;
  &:hover {
    // background-color: #e0e0e0;
  }
`;

const NodeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const NodeIconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const NodeIcon = ({ node }: NodeIconProps) => {
  if (node.children.length === 0) {
    return <Checkbox checked={node.isSelected} />;
  }
  if (node.children.length > 0 && node.isOpen) {
    return <ChevronDown />;
  }
  return <ChevronRight />;
};

export const SelectCategoryTreeNode = ({ node, onClick }: SelectCategoryTreeNodeProps) => {
  return (
    <>
      <NodeWrapper depth={node.depth} onClick={() => onClick(node)}>
        <NodeContent>
          <NodeIconWrapper>
            <NodeIcon node={node} />
          </NodeIconWrapper>
          <NodeTitle>{node.name}</NodeTitle>
        </NodeContent>
        {node.children.length > 0 && <NodeChildCount>{node.children.length}</NodeChildCount>}
      </NodeWrapper>
      {node.isOpen &&
        node.children.map((child) => <SelectCategoryTreeNode key={child.id} node={child} onClick={onClick} />)}
    </>
  );
};
