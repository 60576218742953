import styled from '@emotion/styled';
import { Badge } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import textLogo from '../../assets/images/text-logo.png';
import { Roles } from '../../auth/roles';
import { useUser } from '../../context/UserProvider';
import { DefaultOutlinedButton } from '../../layout';
import { paths } from '../../navigation/paths';
import { colors } from '../../static/colors';
import { SearchRegistration } from '../search/SearchRegistration';
import { MobileMenu } from './MobileMenu';
import { UserMenu } from './UserMenu';
import { useFinancingCountQuery } from '../../api/protected/financings';
import { useLeasesCountQuery } from '../../api/protected/leases';
import { useInsuranceCountQuery } from '../../api/protected/insurances';

const HeaderContainer = styled.header`
  width: 100%;
  min-width: 300px;
  background-color: ${colors.blue};
  @media (min-width: 600px) {
    position: fixed;
    z-index: 100;
  }
`;
const HeaderContent = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
`;
const Logo = styled.img`
  width: auto;
  height: 30px;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const TextLogo = styled.img`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    width: auto;
    height: 25px;
  }
`;

const Navigation = styled.nav`
  display: none;
  @media (min-width: 600px) {
    display: flex;
    color: #fff;
    align-items: center;
  }
`;

const MobileNavigation = styled.div`
  width: 110px;

  @media (min-width: 600px) {
    display: none;
  }
`;
const MobileUserMenu = styled.div`
  width: 110px;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 600px) {
    display: none;
  }
`;

const SignInLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding-right: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const HeaderLink = styled(Link)`
  display: none;

  @media (min-width: 720px) {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 15px;
    white-space: nowrap;
  }
`;

const LogoAndSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding-right: 30px;

  @media (min-width: 600px) {
    width: 100%;
  }
`;

const HeaderBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${colors.orange};
    color: #fff;
    right: -5px;
  }
`;

const Search = styled.div`
  display: none;
  @media (min-width: 600px) {
    display: block;
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

const MobileSearch = styled.div`
  display: block;
  padding: 20px;
  @media (min-width: 600px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

type FinancingLinkProps = {
  financierId: number;
};

const FinancingLink = ({ financierId }: FinancingLinkProps) => {
  const financingCountQuery = useFinancingCountQuery(financierId);

  return (
    <>
      <HeaderLink to={paths.financings.path}>
        <HeaderBadge badgeContent={financingCountQuery?.data} color="primary">
          {paths.financings.name}
        </HeaderBadge>
      </HeaderLink>
    </>
  );
};

type LessorLinkProps = {
  lessorId: number;
};

const LessorLink = ({ lessorId }: LessorLinkProps) => {
  const leasingCountQuery = useLeasesCountQuery(lessorId);

  return (
    <>
      <HeaderLink to={paths.leases.path}>
        <HeaderBadge badgeContent={leasingCountQuery?.data} color="primary">
          {paths.leases.name}
        </HeaderBadge>
      </HeaderLink>
    </>
  );
};

type InsurerLinkProps = {
  insurerId: number;
};

const InsurerLink = ({ insurerId }: InsurerLinkProps) => {
  const insuranceCountQuery = useInsuranceCountQuery(insurerId);

  return (
    <>
      <HeaderLink to={paths.insurances.path}>
        <HeaderBadge badgeContent={insuranceCountQuery?.data} color="primary">
          {paths.insurances.name}
        </HeaderBadge>
      </HeaderLink>
    </>
  );
};

export const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userProfile, selectedOwner } = useUser();
  const { pathname } = useLocation();
  const financierId = isAuthenticated && selectedOwner?.isFinancier ? selectedOwner?.ownerId : undefined;
  const lessorId = isAuthenticated && selectedOwner?.isLessor ? selectedOwner?.ownerId : undefined;
  const insurerId = isAuthenticated && selectedOwner?.isInsurer ? selectedOwner?.ownerId : undefined;

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <MobileNavigation>
            <MobileMenu />
          </MobileNavigation>
          <LogoAndSearch>
            <Link to={paths.home.path}>
              <Logo src={logo} alt="Chassikollen" />
              <TextLogo src={textLogo} alt="Chassikollen" />
            </Link>
            <Search>{pathname !== '/' && <SearchRegistration dense />}</Search>
          </LogoAndSearch>

          <MobileUserMenu>
            {isAuthenticated ? (
              <UserMenu />
            ) : (
              <DefaultOutlinedButton size="small" onClick={() => navigate('/logga-in')}>
                Logga in
              </DefaultOutlinedButton>
            )}
          </MobileUserMenu>

          <Navigation>
            {!isAuthenticated && <SignInLink to={paths.signIn.path}>Logga in</SignInLink>}
            {isAuthenticated && (
              <>
                {financierId && <FinancingLink financierId={financierId} />}
                {lessorId && <LessorLink lessorId={lessorId} />}
                {insurerId && <InsurerLink insurerId={insurerId} />}
                {(userProfile?.roles.includes(Roles.Admin) || userProfile?.roles.includes(Roles.Owner)) && (
                  <>
                    <HeaderLink to={paths.myRegistrations.path}>{paths.myRegistrations.name}</HeaderLink>
                  </>
                )}
                <UserMenu />
              </>
            )}
          </Navigation>
        </HeaderContent>
      </HeaderContainer>
      {pathname !== '/' && (
        <MobileSearch>
          <SearchRegistration dense />
        </MobileSearch>
      )}
    </>
  );
};
