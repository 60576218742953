import { Alert, Container, Typography } from '@mui/material';
import { OwnerRegistrations } from '../../../components/my-registrations/OwnerRegistrations';
import { Divider, PageContent, PageText, PageTitle } from '../../../layout';
import { BreadCrumbs } from '../../../navigation/BreadCrumbs';
import { useConfirmedRegistrationsByOwnerQuery } from '../../../api/protected/registrations';

export const ConfirmedRegistrationsPage = () => {
  const confirmedRegistrations = useConfirmedRegistrationsByOwnerQuery();

  return (
    <>
      <Container maxWidth={false}>
        <BreadCrumbs />
        <PageContent>
          {confirmedRegistrations.data ? (
            <>
              {confirmedRegistrations.data.length === 0 ? (
                <PageTitle>Inga registreringar skapade</PageTitle>
              ) : (
                <>
                  <PageTitle>Mina registreringar</PageTitle>

                  <PageText alignLeft>
                    <Typography variant="body1" gutterBottom>
                      Detta är en översikt av dina registrerade och godkända objekt.
                    </Typography>
                  </PageText>

                  <Divider />

                  <OwnerRegistrations type="confirmed" registrations={confirmedRegistrations.data} />
                </>
              )}
            </>
          ) : (
            confirmedRegistrations.isPending && <Alert severity="warning">Laddar...</Alert>
          )}
          {confirmedRegistrations.isError && (
            <Alert severity="error">Ett fel uppstod vid hämtning av registreringar</Alert>
          )}
        </PageContent>
      </Container>
    </>
  );
};
