import { useNavigate } from 'react-router-dom';
import { usePostAdminRegistrationMutation } from '../../api/admin';
import { paths } from '../../navigation/paths';
import { PostRegistrationDto } from '../../shared/types/registration';
import { SavingDialog } from '../../components/dialogs/SavingDialog';
import { NewRegistrationForm } from '../../components/registrations/NewRegistrationForm';

export const NewAdminRegistrationPage = () => {
  const navigate = useNavigate();

  const postAdminRegistrationMutation = usePostAdminRegistrationMutation();

  function onSaveHandler(registration: PostRegistrationDto) {
    postAdminRegistrationMutation.mutate(registration, {
      onSuccess: () => {
        navigate(paths.editRegistrations.path, { replace: true });
      },
    });
  }

  return (
    <>
      <SavingDialog open={postAdminRegistrationMutation.isPending} />
      {<NewRegistrationForm source={null} onSave={onSaveHandler} isAdmin />}
    </>
  );
};
