import styled from '@emotion/styled';
import { Box, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { Plus } from 'react-feather';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUsersAsync, postUserAsync, putUserAsync } from '../../api/admin';
import { DataGridHeader, DefaultButton, PageTitle } from '../../layout';
import { UserDto } from '../../shared/types/user';
import { EditUserDialog } from '../../components/admin/users/EditUserDialog';
import { NewUserDialog } from '../../components/admin/users/NewUserDialog';
import { UserList } from '../../components/admin/users/UserList';

const PlusIcon = styled(Plus)`
  margin-right: 5px;
`;

export const EditUsersPage = () => {
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState<UserDto | null>(null);

  const queryClient = useQueryClient();

  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsersAsync(),
    refetchOnWindowFocus: false,
  });

  const { mutate: saveEditedUser } = useMutation({
    mutationFn: (user: UserDto) => putUserAsync(user),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
  const { mutate: saveNewUser } = useMutation({
    mutationFn: (user: UserDto) => postUserAsync(user),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  const onEditUser = async (user: UserDto) => {
    setUserToUpdate(user);
    setIsEditUserDialogOpen(true);
  };

  const onSaveEditedUser = async (user: UserDto | null) => {
    if (!user) {
      return;
    }

    setIsEditUserDialogOpen(false);

    saveEditedUser(user);
  };

  const onSaveNewUser = async (user: UserDto | null) => {
    if (!user) {
      return;
    }

    setIsNewUserDialogOpen(false);

    saveNewUser(user);
  };

  const onEditUserDialogClose = () => {
    setIsEditUserDialogOpen(false);
    setIsNewUserDialogOpen(false);
  };

  return (
    <>
      <PageTitle>Redigera användare</PageTitle>

      <EditUserDialog
        isOpen={isEditUserDialogOpen}
        onClose={onEditUserDialogClose}
        onSave={onSaveEditedUser}
        userToUpdate={userToUpdate}
      />
      <NewUserDialog isOpen={isNewUserDialogOpen} onClose={onEditUserDialogClose} onSave={onSaveNewUser} />

      <DataGridHeader>
        <Box></Box>
        <DefaultButton onClick={() => setIsNewUserDialogOpen(true)}>
          <PlusIcon />
          Lägg till ny användare
        </DefaultButton>
      </DataGridHeader>

      {isLoading && <LinearProgress />}

      <UserList users={users} onEditUser={onEditUser} />
    </>
  );
};
