import { Container } from '@mui/material';
import { StatusList } from '../../../components/my-registrations/StatusList';
import { PageContent, PageTitle } from '../../../layout';
import { BreadCrumbs } from '../../../navigation/BreadCrumbs';

export const StatusPage = () => {
  return (
    <>
      <Container>
        <BreadCrumbs />
        <PageContent>
          <PageTitle>Pågående ärenden</PageTitle>
          <StatusList />
        </PageContent>
      </Container>
    </>
  );
};
