import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFinancingOwnerChanges } from '../../api/protected/financings';
import { DataGridHeader, PageTitle } from '../../layout';
import { FinancingActionType, FinancingOwnerChangeListItemDto } from '../../shared/types/financing';

const isInFilter = (financing: FinancingOwnerChangeListItemDto, filter: string) => {
  if (!filter) {
    return true;
  }

  const filterLower = filter.toLowerCase();
  const financier = financing.financier.toLowerCase();
  const externalId = financing.externalId.toLowerCase();
  const registrations = financing.registrations
    .map((r) => r)
    .join(',')
    .toLowerCase();

  return financier.includes(filterLower) || externalId.includes(filterLower) || registrations.includes(filterLower);
};

const getActionRequiredText = (type: FinancingActionType) => {
  switch (type) {
    case FinancingActionType.Resolve:
      return 'Väntar på att lösas';
  }
};

const getConvertedFinancings = (financings: FinancingOwnerChangeListItemDto[]) => {
  return financings.map((financing) => {
    return {
      ...financing,
      registrationsText: financing.registrations.map((r) => r).join(', '),
      actionRequiredText: getActionRequiredText(financing.actionRequired),
    };
  });
};

const useFinancingsQuery = (filter: string) => {
  return useQuery({
    queryKey: ['financings'],
    queryFn: () => getFinancingOwnerChanges(),
    select: (data) => {
      const financings = getConvertedFinancings(data);
      if (!filter) {
        return financings;
      }
      return financings.filter((financing) => isInFilter(financing, filter));
    },
  });
};

export const EditOwnershipPage = () => {
  const [filter] = useState('');
  /* 
  const { data: registrations, isLoading } = useQuery(['ownershipRegistrations'], () => {
    return getAllOwnerChangingRegistrations();
  }); */

  const { data: financings, isLoading } = useFinancingsQuery(filter);

  return (
    <>
      <PageTitle>Ägarbyten</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Finansieringar som behöver åtgärd</Typography>

          <DataGridHeader></DataGridHeader>
          {financings && !isLoading && <>{/*   <FinancingList financings={financings} /> */}</>}
        </Grid>
      </Grid>
    </>
  );
};
