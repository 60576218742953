import styled from '@emotion/styled';
import { useState } from 'react';
import { Plus } from 'react-feather';
import { useDeletePersonMutation, usePostPersonMutation, usePutPersonMutation } from '../../api/admin/persons';
import { useGetPersonsQuery } from '../../api/admin/persons';
import { DataGridFooter, DataGridHeader, DefaultButton, FilterInput, LoadingWrapper } from '../../layout';
import { PersonDto } from '../../shared/types/owner';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { ErrorDialog } from '../../components/dialogs/ErrorDialog';
import { AddPersonDialog } from '../../components/admin/owners/persons/AddPersonDialog';
import { PersonList } from '../../components/admin/owners/persons/PersonList';
import { EditPersonDialog } from '../../components/admin/owners/persons/EditPersonDialog';
import { useDebounce } from '@uidotdev/usehooks';
import { Box, CircularProgress, MenuItem, Pagination, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';

const PlusIcon = styled(Plus)`
  margin-right: 5px;
`;

export const EditPersonsPage = () => {
  const [filter, setFilter] = useState<string>('');
  const [personToDelete, setPersonToDelete] = useState<PersonDto | null>(null);
  const [personToUpdate, setPersonToUpdate] = useState<PersonDto | null>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const [isAddPersonDialogOpen, setIsAddPersonDialogOpen] = useState(false);
  const [isEditPersonDialogOpen, setIsEditPersonDialogOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const searchParams = useDebounce(filter, 500);
  const getPersonsQuery = useGetPersonsQuery(searchParams, page, rowsPerPage);

  const postPersonMutation = usePostPersonMutation();
  const putPersonMutation = usePutPersonMutation();
  const deletePersonMutation = useDeletePersonMutation();

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const onDeletePerson = (person: PersonDto) => {
    setPersonToDelete(person);
    setIsConfirmDeleteDialogOpen(true);
  };

  const onDeletePersonCancel = () => {
    setIsConfirmDeleteDialogOpen(false);
    setTimeout(() => setPersonToDelete(null), 500);
  };

  const onDeletePersonConfirm = async () => {
    if (!personToDelete?.id) {
      onDeletePersonCancel();
      return;
    }
    setError(null);
    deletePersonMutation.mutate(personToDelete.id, {
      onSuccess: () => {
        setIsConfirmDeleteDialogOpen(false);
        setTimeout(() => setPersonToDelete(null), 1000);
      },
      onError: () => {
        setError('Något gick fel när personen skulle raderas. Försök igen senare.');
      },
    });
  };

  const onEditPerson = (person: PersonDto) => {
    console.log('Edit person', person);
    setPersonToUpdate(person);
    setIsEditPersonDialogOpen(true);
  };

  const onAddOrEditPersonDialogClose = () => {
    setIsAddPersonDialogOpen(false);
    setIsEditPersonDialogOpen(false);
    setPersonToUpdate(null);
  };

  const onAddPersonSave = async (person: PersonDto | null) => {
    if (!person) {
      return;
    }
    setError(null);
    postPersonMutation.mutate(person, {
      onSuccess: () => {
        setIsAddPersonDialogOpen(false);
      },
      onError: (error) => {
        const { status } = error as AxiosError;

        if (status === 409) {
          setError('Det finns redan en person med det personnummret');
          return;
        }

        setError('Något gick fel när personen skulle läggas till. Försök igen senare.');
      },
    });
  };

  const onEditPersonSave = async (person: PersonDto | null) => {
    if (!person) {
      return;
    }
    setError(null);
    putPersonMutation.mutate(person, {
      onSuccess: () => {
        setIsEditPersonDialogOpen(false);
      },
      onError: () => {
        setError('Något gick fel när personen skulle ändras. Försök igen senare.');
      },
    });
  };

  return (
    <>
      <ErrorDialog isOpen={error !== null} message={error} onClose={() => setError(null)}></ErrorDialog>

      <DataGridHeader>
        <FilterInput type="search" value={filter} onChange={(event) => onFilterChange(event)} placeholder="Filter" />

        <DefaultButton onClick={() => setIsAddPersonDialogOpen(true)}>
          <PlusIcon />
          Lägg till ny person
        </DefaultButton>
      </DataGridHeader>

      <PersonList persons={getPersonsQuery.data?.persons} onDeletePerson={onDeletePerson} onEditPerson={onEditPerson} />

      <DataGridFooter>
        {getPersonsQuery.data ? (
          <Pagination
            count={Math.ceil(getPersonsQuery.data.total / rowsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            showFirstButton
            showLastButton
          />
        ) : (
          <>
            <Box></Box>
          </>
        )}

        <TextField
          select
          value={rowsPerPage}
          onChange={(event) => setRowsPerPage(+event.target.value)}
          helperText="Välj antal rader per sida"
          size="small"
        >
          {[5, 10, 25, 50, 100, 500].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DataGridFooter>

      <ConfirmDialog
        isOpen={isConfirmDeleteDialogOpen}
        onConfirm={onDeletePersonConfirm}
        onCancel={onDeletePersonCancel}
        title="Är du säker?"
        message={`Vill du verkligen radera "${personToDelete?.givenName} ${personToDelete?.familyName}"`}
      ></ConfirmDialog>

      <AddPersonDialog isOpen={isAddPersonDialogOpen} onClose={onAddOrEditPersonDialogClose} onSave={onAddPersonSave} />

      <EditPersonDialog
        isOpen={isEditPersonDialogOpen}
        onClose={onAddOrEditPersonDialogClose}
        personToUpdate={personToUpdate}
        onSave={onEditPersonSave}
      />

      {getPersonsQuery.isLoading && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}

      {getPersonsQuery.isError && (
        <Box>
          <Typography variant="body1">Ett fel uppstod när personen skulle laddas</Typography>
        </Box>
      )}
    </>
  );
};
