import styled from '@emotion/styled';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserProvider';
import { Divider, MenuSectionTitle } from '../../layout';
import { paths } from '../../navigation/paths';
import { SelectUserCompany } from './SelectUserCompany';

import { Badge } from '@mui/material';
import { useChangeOfOwnershipRegistrationsByOwnerQuery } from '../../api/protected/registrations';

const Wrapper = styled.div`
  top: 60px;
  position: fixed;
  height: calc(100vh - 60px);
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  a {
    color: #000;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 5px;
    min-height: auto;
  }

  @media (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    display: block;
    width: 300px;
    border-right: 1px solid #eee;
    background-color: rgba(0, 0, 0, 0.05);

    a {
      font-size: 1rem;
      padding: 6px 16px;
    }
  }
`;

const UserInfo = styled.div`
  padding: 0 16px 0;
`;

export const MyRegistrationsMenu = () => {
  const { selectedOwner, isRegistrar } = useUser();
  const changeOfOwnershipRegistrations = useChangeOfOwnershipRegistrationsByOwnerQuery();

  return (
    <Wrapper>
      <MenuSectionTitle>Företräder</MenuSectionTitle>
      <UserInfo>
        <Typography variant="subtitle1" gutterBottom>
          {selectedOwner?.fullName}
        </Typography>

        <SelectUserCompany textButton="Ändra" />
      </UserInfo>
      <Divider />

      <MenuItem component={Link} to={paths.myRegistrations.path}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        Översikt
      </MenuItem>
      <MenuItem component={Link} to={paths.myRegistrationsRegistrations.path}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        Registreringar
      </MenuItem>

      {isRegistrar && (
        <>
          <MenuItem component={Link} to={paths.myRegistrationsNewRegistration.path}>
            <ListItemIcon>
              <PlusCircle />
            </ListItemIcon>
            {paths.myRegistrationsNewRegistration.name}
          </MenuItem>
        </>
      )}

      <Divider />
      <MenuSectionTitle>Pågående ärenden</MenuSectionTitle>

      <MenuItem component={Link} to={paths.myRegistrationsChangeOfOwnershipRegistrations.path}>
        <ListItemIcon>
          <Badge color="primary" badgeContent={changeOfOwnershipRegistrations.data?.filter((r) => !r.parentId).length}>
            <NextPlanIcon />
          </Badge>
        </ListItemIcon>
        Pågående ägarbyten
      </MenuItem>
    </Wrapper>
  );
};
