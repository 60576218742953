import styled from '@emotion/styled';
import { Divider as MuiDevider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TextLink } from '../../layout';
import { CategoryDto } from '../../shared/types/protected/category';
import { TreeNodeData } from './types';
import { SelectCategoryTreeNode } from './SelectCategoryTreeNode';
import { useCategoriesQuery } from '../../api/protected/categories';

type SelectCategoryTreeProps = {
  onSelectCategory: (category: CategoryDto | null) => void;
  selectedCategory: CategoryDto | null;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
`;

const Divider = styled(MuiDevider)`
  margin-top: 5px;
  margin-bottom: 10px;
`;

const unselectAllNodes = (treeData: TreeNodeData[]) => {
  treeData.forEach((node) => {
    node.isSelected = false;
    unselectAllNodes(node.children);
  });
};

export const SelectCategoryTree = ({ onSelectCategory, selectedCategory }: SelectCategoryTreeProps) => {
  const [treeData, setTreeData] = useState<TreeNodeData[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);

  const { data: categories } = useCategoriesQuery(true);

  useEffect(() => {
    setTreeData(getTreeData(categories ?? []));
  }, [categories, selectedCategory]);

  const getTreeData = (categories: CategoryDto[], parentId?: number | null, depth?: number): TreeNodeData[] => {
    parentId = parentId ?? null;

    const treeData: TreeNodeData[] = categories
      .filter((c) => c.parentId === parentId)
      .map((c) => ({
        id: c.id as number,
        name: c.name,
        children: getTreeData(categories, c.id as number, depth ? depth + 1 : 1),
        depth: depth ?? 0,
        isOpen: false,
        isSelected: selectedCategory?.id === c.id,
      }));

    return treeData;
  };

  const onClick = (node: TreeNodeData) => {
    if (!node) {
      return;
    }

    if (node.children.length > 0) {
      node.isOpen = !node.isOpen;
      setTreeData([...treeData]);
      return;
    }

    unselectAllNodes(treeData);

    node.isSelected = true;

    const category = categories?.find((c) => c.id === node.id);
    onSelectCategory(category ?? null);
    setSelectedNodeId(node.id);

    /*  if (node.id !== selectedNodeId) {
      const category = categories.find((c) => c.id === node.id);
      onSelectCategory(category ?? null);
      setSelectedNodeId(node.id);
    } else {
      onSelectCategory(null);
    } */

    setTreeData([...treeData]);
  };

  const openAllNodes = (treeData: TreeNodeData[]) => {
    treeData.forEach((node) => {
      node.isOpen = true;
      openAllNodes(node.children);
    });
  };

  const closeAllNodes = (treeData: TreeNodeData[]) => {
    treeData.forEach((node) => {
      node.isOpen = false;
      closeAllNodes(node.children);
    });
  };

  const onOpenAllCategoriesClick = () => {
    openAllNodes(treeData);
    setTreeData([...treeData]);
  };

  const onCloseAllCategoriesClick = () => {
    closeAllNodes(treeData);
    setTreeData([...treeData]);
  };

  return (
    <>
      <Header>
        <Typography variant="h6">Välj kategori</Typography>
        <ToggleButtons>
          <TextLink onClick={onOpenAllCategoriesClick}>Öppna alla</TextLink>
          <TextLink onClick={onCloseAllCategoriesClick}>Stäng alla</TextLink>
        </ToggleButtons>
      </Header>

      <Divider />

      {treeData.map((node, i) => (
        <SelectCategoryTreeNode node={node} key={i} onClick={onClick} />
      ))}
    </>
  );
};
