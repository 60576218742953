import styled from '@emotion/styled';
import { useState } from 'react';
import { Plus } from 'react-feather';
import {
  useGetCompaniesQuery,
  useDeleteCompanyMutation,
  usePostCompanyMutation,
  usePutCompanyMutation,
  useUploadInsuranceCompaniesMutation,
} from '../../api/admin/companies';
import {
  DataGridFooter,
  DataGridHeader,
  DefaultButton,
  DefaultTextButton,
  FilterInput,
  LoadingWrapper,
} from '../../layout';
import { CompanyDto } from '../../shared/types/owner';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { ErrorDialog } from '../../components/dialogs/ErrorDialog';
import { AddCompanyDialog } from '../../components/admin/owners/companies/AddCompanyDialog';
import { CompanyList } from '../../components/admin/owners/companies/CompanyList';
import { EditCompanyDialog } from '../../components/admin/owners/companies/EditCompanyDialog';
import { useDebounce } from '@uidotdev/usehooks';
import {
  Box,
  CircularProgress,
  Pagination,
  Typography,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Link,
} from '@mui/material';
import { AxiosError } from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PlusIcon = styled(Plus)`
  margin-right: 5px;
`;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const EditCompaniesPage = () => {
  const [filter, setFilter] = useState<string>('');
  const [companyToDelete, setCompanyToDelete] = useState<CompanyDto | null>(null);
  const [companyToUpdate, setCompanyToUpdate] = useState<CompanyDto | null>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const [isAddCompanyDialogOpen, setIsAddCompanyDialogOpen] = useState(false);
  const [isEditCompanyDialogOpen, setIsEditCompanyDialogOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFinancier, setIsFinancier] = useState<boolean>(false);
  const [isLessor, setIsLessor] = useState<boolean>(false);
  const [isInsurer, setIsInsurer] = useState<boolean>(false);

  const debouncedFilter = useDebounce(filter, 500);
  const getCompaniesQuery = useGetCompaniesQuery(debouncedFilter, page, rowsPerPage, isFinancier, isLessor, isInsurer);

  const postCompanyMutation = usePostCompanyMutation();
  const putCompanyMutation = usePutCompanyMutation();
  const deleteCompanyMutation = useDeleteCompanyMutation();
  const uploadInsuranceCompaniesMutation = useUploadInsuranceCompaniesMutation();

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const onDeleteCompany = (company: CompanyDto) => {
    setCompanyToDelete(company);
    setIsConfirmDeleteDialogOpen(true);
  };

  const onDeleteCompanyCancel = () => {
    setIsConfirmDeleteDialogOpen(false);
    setTimeout(() => setCompanyToDelete(null), 500);
  };

  const onDeleteCompanyConfirm = async () => {
    if (!companyToDelete?.id) {
      onDeleteCompanyCancel();
      return;
    }
    setError(null);
    deleteCompanyMutation.mutate(companyToDelete.id, {
      onSuccess: () => {
        setIsConfirmDeleteDialogOpen(false);
        setTimeout(() => setCompanyToDelete(null), 1000);
      },
      onError: () => {
        setError('Något gick fel när företaget skulle raderas. Försök igen senare.');
      },
    });
  };

  const onEditCompany = (company: CompanyDto) => {
    setCompanyToUpdate(company);
    setIsEditCompanyDialogOpen(true);
  };

  const onAddOrEditCompanyDialogClose = () => {
    setIsAddCompanyDialogOpen(false);
    setIsEditCompanyDialogOpen(false);
    setCompanyToUpdate(null);
  };

  const onAddCompanySave = async (company: CompanyDto | null) => {
    if (!company) {
      return;
    }
    setError(null);
    postCompanyMutation.mutate(company, {
      onSuccess: () => {
        setIsAddCompanyDialogOpen(false);
      },
      onError: (error) => {
        const { status } = error as AxiosError;

        if (status === 409) {
          setError('Det finns redan en företag med det organisationsnumret');
          return;
        }

        setError('Något gick fel när företaget skulle läggas till. Försök igen senare.');
      },
    });
  };

  const onEditCompanySave = async (company: CompanyDto | null) => {
    if (!company) {
      return;
    }
    setError(null);
    putCompanyMutation.mutate(company, {
      onSuccess: () => {
        setIsEditCompanyDialogOpen(false);
      },
      onError: () => {
        setError('Något gick fel när företaget skulle ändras. Försök igen senare.');
      },
    });
  };

  const onInsuranceUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const formData = new FormData();

      formData.append('file', files[0]);

      uploadInsuranceCompaniesMutation.mutate(formData, {
        onError: () => {
          setError('Något gick fel när försäkringsbolagen skulle laddas upp. Försök igen senare.');
        },
      });
    }
  };

  return (
    <>
      <ErrorDialog isOpen={error !== null} message={error} onClose={() => setError(null)}></ErrorDialog>

      <DataGridHeader>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FilterInput type="search" value={filter} onChange={(event) => onFilterChange(event)} placeholder="Filter" />
          <FormGroup sx={{ position: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFinancier}
                  onChange={(e) => {
                    setIsFinancier(e.target.checked);
                    setPage(1);
                  }}
                />
              }
              label="Finansiärer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLessor}
                  onChange={(e) => {
                    setIsLessor(e.target.checked);
                    setPage(1);
                  }}
                />
              }
              label="Leasingbolag"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isInsurer}
                  onChange={(e) => {
                    setIsInsurer(e.target.checked);
                    setPage(1);
                  }}
                />
              }
              label="Försäkringsbolag"
            />
          </FormGroup>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {isInsurer && (
            <Tooltip
              title={
                <Typography variant="body1">
                  Ladda upp försäkringsbolag med fil från{' '}
                  <Link href="https://www.fi.se/sv/vara-register/foretagsregistret/?huvudkategori=F%C3%B6rs%C3%A4kringsf%C3%B6retag&area=#results">
                    Finansinspektionen
                  </Link>
                </Typography>
              }
            >
              <DefaultTextButton
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload Insurance
                <VisuallyHiddenInput type="file" onChange={(e) => onInsuranceUpload(e)} value={''} />
              </DefaultTextButton>
            </Tooltip>
          )}
          <DefaultButton onClick={() => setIsAddCompanyDialogOpen(true)}>
            <PlusIcon />
            Lägg till nytt företag
          </DefaultButton>
        </Box>
      </DataGridHeader>

      <CompanyList
        companies={getCompaniesQuery.data?.companies}
        onDeleteCompany={onDeleteCompany}
        onEditCompany={onEditCompany}
      />

      <DataGridFooter>
        {getCompaniesQuery.data ? (
          <Pagination
            count={Math.ceil(getCompaniesQuery.data.total / rowsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            showFirstButton
            showLastButton
          />
        ) : (
          <>
            <Box></Box>
          </>
        )}

        <TextField
          select
          value={rowsPerPage}
          onChange={(event) => setRowsPerPage(+event.target.value)}
          helperText="Välj antal rader per sida"
          size="small"
        >
          {[5, 10, 25, 50, 100, 500].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DataGridFooter>

      <ConfirmDialog
        isOpen={isConfirmDeleteDialogOpen}
        onConfirm={onDeleteCompanyConfirm}
        onCancel={onDeleteCompanyCancel}
        title="Är du säker?"
        message={`Vill du verkligen radera "${companyToDelete?.name}"`}
      ></ConfirmDialog>

      <AddCompanyDialog
        isOpen={isAddCompanyDialogOpen}
        onClose={onAddOrEditCompanyDialogClose}
        onSave={onAddCompanySave}
      />

      <EditCompanyDialog
        isOpen={isEditCompanyDialogOpen}
        onClose={onAddOrEditCompanyDialogClose}
        companyToUpdate={companyToUpdate}
        onSave={onEditCompanySave}
      />

      {getCompaniesQuery.isLoading && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}

      {getCompaniesQuery.isError && (
        <Box>
          <Typography variant="body1">Ett fel uppstod när företagen skulle laddas</Typography>
        </Box>
      )}
    </>
  );
};
