import styled from '@emotion/styled';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../components/header/Header';
import { Footer } from '../components/main/Footer';
import { ConfigProvider } from '../context/ConfigProvider';
import { UserProvider } from '../context/UserProvider';
import { colors } from '../static/colors';
import { theme } from '../theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { paths } from '../navigation/paths';

dayjs.extend(relativeTime);
dayjs.locale('sv');
const MainContent = styled.main`
  min-width: 300px;
  min-height: calc(100vh - 410px);

  @media (min-width: 600px) {
    padding-top: 60px;
  }

  a {
    text-decoration: none;
    color: ${colors.orange};
  }
`;

const queryClient = new QueryClient();

const getPathName = (path: string) => {
  return Object.values(paths).find((p) => p.path === replaceLastPartOfPath(path))?.name;
};

const replaceLastPartOfPath = (path: string) => {
  if (!path) return path;

  const lastPartOfPath = path.split('/').pop();
  if (lastPartOfPath && !isNaN(Number(lastPartOfPath))) {
    path = path.replace(lastPartOfPath, ':id');
  }
  return path;
};

export const Root = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const title = [getPathName(pathname), 'Chassikollen']
    .filter((item) => item !== undefined && item !== null)
    .join(' - ');

  useEffect(() => {
    document.title = title;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Header />
              <MainContent>
                <Outlet />
              </MainContent>
              {!pathname.startsWith('/admin') && <Footer />}
            </LocalizationProvider>
          </UserProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};
