import { CompanyDto } from './owner';
import { OwnerDto } from './owner';
import { RegistrationDto, RegistrationListItemDto } from './registration';

export type FinancingDto = {
  id: number;
  externalId: string | null;
  resolvedAt: string | null;
  resolvedComment: string | null;
  financier: CompanyDto;
  borrower: OwnerDto;
  registrations: RegistrationDto[];
};

export enum FinancingActionType {
  None = 0,
  Resolve = 2,
}

export enum TransactionType {
  None = 0,
  Financed = 1,
  CashPurchase = 2,
  Lease = 3,
}

export type FinancingOwnerChangeListItemDto = {
  id: number;
  financier: string;
  borrower: string;
  externalId: string;
  registrations: RegistrationListItemDto[];
  registrationsText?: string;
  actionRequired: FinancingActionType;
  actionRequiredText?: string;
};

export type FinancingOwnerListItemDto = {
  id: number;
  name: string;
};

export type FinancingDetailsDto = {
  id: number;
  externalId: string | null;
  resolvedAt: string | null;
  resolvedComment: string | null;
  financier: CompanyDto;
  registrations: RegistrationListItemDto[];
  actionRequired: FinancingActionType;
};

export type OwnerFinancingStatusDto = {
  financier: CompanyDto | null;
  externalId: string | null;
  financingResolvedAt: string | null;
};

export type OwnerLeaseStatusDto = {
  financier: CompanyDto | null;
  externalId: string | null;
  expires: string | null;
  holder: OwnerDto;
};
