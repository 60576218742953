import styled from '@emotion/styled';
import { AdminPanelSettings, Business, SentimentSatisfiedRounded, SwitchAccount } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronRight, LogOut, User } from 'react-feather';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserProvider';
import { SelectUserOwner } from './SelectUserOwner';
import { Roles } from '../../auth/roles';
import { paths } from '../../navigation/paths';
import { OwnerTypeDto } from '../../shared/types/owner';

const UserMenuWrapper = styled.div`
  width: 320px;
  a {
    text-decoration: none;
    color: '#000';
  }
`;

const UserButton = styled.div`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
`;

const UserProfile = styled.div`
  padding: 24px 16px 24px 16px;
  margin-top: -8px;
  margin-bottom: 8px;

  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const CurrentOwner = styled.div``;

const SentimentSatisfiedRoundedIcon = styled(SentimentSatisfiedRounded)`
  margin-right: 10px;
  margin-left: -5px;
`;
const BusinessIcon = styled(Business)`
  margin-right: 10px;
  margin-left: -5px;
`;
const UserIcon = styled(User)`
  margin-right: 10px;
  margin-left: -5px;
`;
const ChevronDownIcon = styled(ChevronDown)`
  margin-left: 5px;
`;

const UserName = styled.span`
  display: none;
  @media (min-width: 600px) {
    display: inline;
  }
`;

type UserMenuItemsProps = {
  onClick: () => void;
};

const UserMenuItems = ({ onClick }: UserMenuItemsProps) => {
  const [isChangeUserOwnerOpen, setIsChangeUserOwnerOpen] = useState(false);

  const { userProfile, selectedOwner } = useUser();

  const MenuDivider = styled(Divider)`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

  const onChangeUserOwnerClick = () => {
    setIsChangeUserOwnerOpen(!isChangeUserOwnerOpen);
  };

  return (
    <UserMenuWrapper>
      {!isChangeUserOwnerOpen && (
        <>
          {userProfile?.roles.includes(Roles.Owner) && (
            <>
              <UserProfile>
                <Typography variant="body2">Du är inloggad som</Typography>
                <Typography variant="h6">
                  {userProfile?.givenName} {userProfile?.familyName}
                </Typography>

                <>
                  <MenuDivider />

                  <CurrentOwner>
                    <Typography variant="body2">Du företräder</Typography>
                    {selectedOwner?.fullName && (
                      <>
                        <Typography variant="h6">{selectedOwner?.fullName}</Typography>
                      </>
                    )}
                    {!selectedOwner && (
                      <>
                        <Typography variant="h6">Dig själv</Typography>
                      </>
                    )}
                  </CurrentOwner>
                </>
              </UserProfile>

              <MenuItem onClick={onChangeUserOwnerClick}>
                <ListItemIcon>
                  <SwitchAccount />
                </ListItemIcon>
                <ListItemText>Ändra vem du företräder</ListItemText>
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
              </MenuItem>

              <MenuItem component={Link} to={paths.myProfile.path} onClick={onClick}>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText>Min profil</ListItemText>
              </MenuItem>

              <MenuItem component={Link} to={paths.myOwners.path} onClick={onClick}>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText>{paths.myOwners.name}</ListItemText>
              </MenuItem>

              <Divider />
            </>
          )}
          {userProfile?.roles.includes(Roles.Admin) && (
            <>
              <MenuItem component={Link} to={paths.admin.path} onClick={onClick}>
                <ListItemIcon>
                  <AdminPanelSettings />
                </ListItemIcon>
                <ListItemText>Administrera</ListItemText>
              </MenuItem>
            </>
          )}

          <MenuItem component={Link} to={paths.signOut.path} onClick={onClick}>
            <ListItemIcon>
              <LogOut />
            </ListItemIcon>
            <ListItemText>Logga ut</ListItemText>
          </MenuItem>
        </>
      )}
      {isChangeUserOwnerOpen && (
        <SelectUserOwner
          onClose={() => {
            setIsChangeUserOwnerOpen(false);
            onClick();
          }}
        />
      )}
    </UserMenuWrapper>
  );
};

export const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { selectedOwner, userProfile } = useUser();

  const onUserClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserButton onClick={onUserClick}>
        {selectedOwner?.pid === userProfile?.socialSecurityNumber ? (
          <SentimentSatisfiedRoundedIcon />
        ) : selectedOwner?.ownerType === OwnerTypeDto.Company ? (
          <BusinessIcon />
        ) : (
          <UserIcon size={20} />
        )}{' '}
        <UserName>
          {userProfile?.roles.includes(Roles.Owner) ? selectedOwner?.fullName : userProfile?.displayName}
        </UserName>{' '}
        <ChevronDownIcon size={20} />
      </UserButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disableScrollLock
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <UserMenuItems onClick={onClose} />
      </Menu>
    </>
  );
};
