import { Container, Typography } from '@mui/material';
import { StatusList } from '../../../components/my-registrations/StatusList';
import { DefaultLinkButton, Divider, PageContent } from '../../../layout';
import { BreadCrumbs } from '../../../navigation/BreadCrumbs';
import { paths } from '../../../navigation/paths';
import { Statistics } from '../../../pages/my-registrations/Statistics';
import { useUser } from '../../../context/UserProvider';

export const OwnerOverviewPage = () => {
  const { isRegistrar } = useUser();

  return (
    <>
      <Container maxWidth={false}>
        <BreadCrumbs />
        <PageContent>
          <Typography variant="h2">Översikt</Typography>
          <p>
            Detta är en översikt av dina registrerade objekt. Du kan enkelt se vilka registreringar som väntar på
            kontroll, vilka registreringar som blivit kontrollerade och godkända och vilka pågående ägarbyten som finns
            för tillfället.
          </p>
          <Statistics />
          <Divider />

          {isRegistrar && (
            <>
              <DefaultLinkButton to={paths.myRegistrationsNewRegistration.path}>
                Skapa en ny registrering
              </DefaultLinkButton>
              <Divider />
            </>
          )}

          <StatusList />
        </PageContent>
      </Container>
    </>
  );
};
