import { Container } from '@mui/material';
import { PageContent, PageTitle } from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';
import { LeaseList } from '../../components/leases/LeaseList';

export const LeasesPage = () => {
  return (
    <Container>
      <BreadCrumbs />
      <PageContent>
        <PageTitle>Leasingar</PageTitle>
        <LeaseList />
      </PageContent>
    </Container>
  );
};
