import { Container, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { Edit } from 'react-feather';
import { useUser } from '../../context/UserProvider';
import {
  DefaultCard,
  DefaultCardTopActions,
  Divider,
  PageContent,
  PageText,
  PageTitle,
  PageWrapper,
} from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';
import { getFormatedSocialSecurityNumberNumber } from '../../shared/utils/format-pid';
import { EditUserProfileDialog } from '../../pages/my-profile/EditUserProfileDialog';

export const MyProfilePage = () => {
  const { userProfile } = useUser();
  const [isEditUserProfileDialogOpen, setIsEditUserProfileDialogOpen] = useState(false);

  return (
    <>
      <EditUserProfileDialog
        isOpen={isEditUserProfileDialogOpen}
        onClose={() => setIsEditUserProfileDialogOpen(false)}
      />

      <Container>
        <PageWrapper>
          <BreadCrumbs />

          <PageContent>
            <PageTitle>Min profil</PageTitle>

            <PageText alignLeft>Här kan du se och redigera dina användaruppgifter hos oss.</PageText>

            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12} lg={10}>
                <DefaultCard>
                  <DefaultCardTopActions>
                    <IconButton onClick={() => setIsEditUserProfileDialogOpen(true)}>
                      <Edit />
                    </IconButton>
                  </DefaultCardTopActions>
                  <Typography variant="h6" gutterBottom>
                    Kontouppgifter
                  </Typography>
                  <Grid container spacing={2}>
                    {userProfile?.username && (
                      <Grid item xs={12}>
                        <Typography variant="caption">Användarnamn</Typography>
                        <Typography variant="body1">{userProfile?.username}</Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">Visningsnamn</Typography>
                      <Typography variant="body1">{userProfile?.displayName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">Personnummer</Typography>
                      <Typography variant="body1">
                        {getFormatedSocialSecurityNumberNumber(userProfile?.socialSecurityNumber)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">Förnamn</Typography>
                      <Typography variant="body1">{userProfile?.givenName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">Efternamn</Typography>
                      <Typography variant="body1">{userProfile?.familyName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">E-post</Typography>
                      <Typography variant="body1">{userProfile?.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">Telefonnummer</Typography>
                      <Typography variant="body1">{userProfile?.mobilePhone}</Typography>
                    </Grid>
                  </Grid>
                </DefaultCard>
              </Grid>
            </Grid>
          </PageContent>
        </PageWrapper>
      </Container>
    </>
  );
};
