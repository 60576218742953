import styled from '@emotion/styled';
import { MyRegistrationsMenu } from '../../../components/my-registrations/MyRegistrationsMenu';
import { HiddenOnMobile } from '../../../layout';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const Content = styled.div`
  padding: 10px;

  @media (min-width: 600px) {
    flex: 1;
    padding: 20px;
    margin-left: 300px;
    min-height: calc(100vh - 60px);
  }
`;

export const MyRegistrationsPage = () => {
  return (
    <Box>
      <HiddenOnMobile>
        <MyRegistrationsMenu />
      </HiddenOnMobile>
      <Content>
        <Outlet />
      </Content>
    </Box>
  );
};
