import { List, ListItem, Typography, Grid, ListItemText, IconButton, ListItemIcon } from '@mui/material';
import { DefaultCard } from '../../../layout';
import { FinancingListItemDto } from '../../../shared/types/admin/financing';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit as EditIcon } from 'react-feather';

type FinancingListProps = {
  financings: FinancingListItemDto[];
};

export const FinancingList = ({ financings }: FinancingListProps) => {
  return (
    <DefaultCard>
      <List>
        <ListItem>
          <Grid container>
            <Grid item xs>
              <Typography variant="h6">Lånenummer</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6">Finansiär</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6">Låntagare</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {financings.map((financing, i) => (
          <ListItem key={i}>
            <Grid container>
              <Grid item xs>
                <ListItemText primary={financing.externalId} />
              </Grid>
              <Grid item xs>
                <ListItemText primary={financing.financier} />
              </Grid>
              <Grid item xs>
                <ListItemText primary={financing.borrower} />
              </Grid>
            </Grid>
            <ListItemIcon>
              <IconButton edge="end" aria-label="ändra">
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="radera">
                <DeleteIcon />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </DefaultCard>
  );
};
