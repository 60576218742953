import { Alert, Box, LinearProgress, MenuItem, Pagination, TextField } from '@mui/material';
import { useState } from 'react';
import { useDeleteFinancingMutation, useFinancingsQuery, useUpdateFinancingMutation } from '../../api/admin/financings';
import { DataGridFooter, DataGridHeader, DefaultLinkButton, FilterInput, PageTitle } from '../../layout';
import { FinancingListItemDto, FinancingUpdateDto } from '../../shared/types/admin/financing';
import { EditFinancingDialog } from '../../components/admin/financings/EditFinancingDialog';
import { FinancingList } from '../../components/admin/financings/FinancingList';
import { paths } from '../../navigation/paths';
import { useDebounce } from '@uidotdev/usehooks';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { SavingDialog } from '../../components/dialogs/SavingDialog';

export const EditFinancionsPage = () => {
  const [filter, setFilter] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [financingToEdit, setFinancingToEdit] = useState<number | null>(null);
  const [financingToDelete, setFinancingToDelete] = useState<FinancingListItemDto | null>(null);

  const debouncedFilter = useDebounce(filter, 500);

  const financingsQuery = useFinancingsQuery(debouncedFilter, page, rowsPerPage, false);

  const updateFinancingMutation = useUpdateFinancingMutation();
  const deleteFinancingMutation = useDeleteFinancingMutation();

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    //setPage(1);
  };

  const onSave = (financingUpdate: FinancingUpdateDto) => {
    updateFinancingMutation.mutate(financingUpdate);
    setFinancingToEdit(null);
  };

  const onDeleteFinancingConfirm = async () => {
    if (financingToDelete) {
      deleteFinancingMutation.mutate(financingToDelete.id, {
        onSuccess: () => {
          setFinancingToDelete(null);
        },
      });
    }
  };

  return (
    <>
      <PageTitle>Finansieringar</PageTitle>
      <DataGridHeader>
        <FilterInput type="search" value={filter} onChange={(event) => onFilterChange(event)} placeholder="Filter" />

        <DefaultLinkButton to={paths.newFinancing.path}>{paths.newFinancing.name}</DefaultLinkButton>
      </DataGridHeader>

      {financingsQuery.data ? <FinancingList financings={financingsQuery.data.financings} /> : <LinearProgress />}

      {financingsQuery.isError && <Alert severity="error">Det gick inte att hämta registreringar</Alert>}

      <DataGridFooter>
        {financingsQuery.data ? (
          <Pagination
            count={Math.ceil(financingsQuery.data.total / rowsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            showFirstButton
            showLastButton
          />
        ) : (
          <>
            <Box></Box>
          </>
        )}

        <TextField
          select
          value={rowsPerPage}
          onChange={(event) => setRowsPerPage(+event.target.value)}
          helperText="Välj antal rader per sida"
          size="small"
        >
          {[5, 10, 25, 50, 100, 500].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DataGridFooter>

      <ConfirmDialog
        isOpen={!!financingToDelete}
        onConfirm={onDeleteFinancingConfirm}
        onCancel={() => setFinancingToDelete(null)}
        title="Är du säker?"
        message={`Vill du verkligen radera "${financingToDelete?.externalId}"`}
      ></ConfirmDialog>

      <SavingDialog open={updateFinancingMutation.isPending} />

      <EditFinancingDialog
        isOpen={!!financingToEdit}
        onClose={() => setFinancingToEdit(null)}
        financingId={financingToEdit}
        onSave={onSave}
      />

      {/* <Snackbar
  open={isSaved}
  autoHideDuration={6000}
  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
  onClose={handleSnackbarClose}
>
  <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
    Registreringen är sparad!
  </Alert>
</Snackbar> */}
    </>
  );
};
