import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { ArrowLeft } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { RegistrationDetails } from '../../../components/my-registrations/registration-details/RegistrationDetails';
import { PageContent, SubContainer } from '../../../layout';
import { BreadCrumbs } from '../../../navigation/BreadCrumbs';
import { paths } from '../../../navigation/paths';

const BackNavigation = styled.div`
  margin-bottom: 40px;
  @media print {
    display: none;
  }
`;

const BackLink = styled(Link)`
  color: #000 !important;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const OwnerRegistrationDetailsPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Container maxWidth={false}>
        <BreadCrumbs />
        <PageContent>
          <BackNavigation>
            <BackLink to={paths.myRegistrationsRegistrations.path}>
              <ArrowLeft />
              {paths.myRegistrationsRegistrations.name}
            </BackLink>
          </BackNavigation>
          <SubContainer>{id && <RegistrationDetails registrationId={parseInt(id)} />}</SubContainer>
        </PageContent>
      </Container>
    </>
  );
};
