import styled from '@emotion/styled';
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCompanyByOrganizationNumberFromApiAsync,
  getPersonBysocialSecurityNumberFromApiAsync,
} from '../../../api/protected/owners';
import { postChangeOwnershipToApiAsync } from '../../../api/protected/ownership';
import { getOwnerRegistrationDetailsFromApiAsync } from '../../../api/protected/registrations';
import { useUser } from '../../../context/UserProvider';

import { SelectFinancier } from '../../../components/financings/SelectFinancier';
import {
  DefaultButton,
  DefaultCard,
  DefaultTextButton,
  Divider,
  FormInputWrapper,
  PageContent,
  PageTitle,
} from '../../../layout';
import { BreadCrumbs } from '../../../navigation/BreadCrumbs';
import { paths } from '../../../navigation/paths';
import { CompanyDto } from '../../../shared/types/owner';
import { TransactionType } from '../../../shared/types/financing';
import { OwnerChangeDto, OwnerTypeDto } from '../../../shared/types/owner';
import { FinancingTypeDto, RegistrationDetailsDto } from '../../../shared/types/registration';
import { getFormatedOrganizationNumber, getFormatedSocialSecurityNumberNumber } from '../../../shared/utils/format-pid';

const StepperWrapper = styled.div``;
const StepperNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

export const ChangeOfOwnershipPage = () => {
  // TODO: Break this component into smaller components

  const { id } = useParams();
  const [registration, setRegistration] = useState<RegistrationDetailsDto>();
  const [ownerType, setOwnerType] = useState(OwnerTypeDto.Company);

  const [buyerOrganizationNumber, setBuyerOrganizationNumber] = useState<string>('');
  const [buyerOrganizationNumberInput, setBuyerOrganizationNumberInput] = useState<string>('');
  const [buyerSocialSecurityNumber, setBuyerSocialSecurityNumber] = useState<string>('');
  const [buyerSocialSecurityNumberInput, setBuyerSocialSecurityNumberInput] = useState<string>('');

  const [buyerSearchError, setBuyerSearchError] = useState<string | null>(null);

  const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.None);
  const [activeStep, setActiveStep] = useState(0);
  const [financier, setFinancier] = useState<CompanyDto | null>(null);
  const [externalId, setExternalId] = useState('');
  const { selectedOwner, userProfile } = useUser();

  const navigate = useNavigate();

  const buyerCompanyQuery = useQuery({
    queryKey: ['buyerCompany', buyerOrganizationNumber],
    queryFn: () => getCompanyByOrganizationNumberFromApiAsync(buyerOrganizationNumber),
    enabled: !!buyerOrganizationNumber,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (buyerCompanyQuery.error) {
      console.log('error', buyerCompanyQuery.error);
      setBuyerSearchError('Företaget hittades inte');
    }
  }, [buyerCompanyQuery.error]);

  const buyerPersonQuery = useQuery({
    queryKey: ['buyerPerson', buyerSocialSecurityNumber],
    queryFn: () => getPersonBysocialSecurityNumberFromApiAsync(buyerSocialSecurityNumber),
    enabled: !!buyerSocialSecurityNumber,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (buyerPersonQuery.error) {
      console.log('error', buyerPersonQuery.error);
      setBuyerSearchError('Personen hittades inte');
    }
  }, [buyerPersonQuery.error]);

  const ownerChange = useMutation({
    mutationFn: (ownerChange: OwnerChangeDto) => postChangeOwnershipToApiAsync(ownerChange),
    onSuccess: () => {
      console.log('saveChangeOwnership onSuccess');
      navigate(paths.myRegistrationsChangeOfOwnershipComplete.path);
    },
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchData = async () => {
      const ownerId = selectedOwner?.ownerId || userProfile?.ownerId;
      if (!ownerId) {
        return;
      }
      const registration = await getOwnerRegistrationDetailsFromApiAsync(ownerId, parseInt(id));
      if (!registration) {
        return;
      }

      setRegistration(registration);
    };

    fetchData();
  }, [id]);

  const getFinancingType = (transactionType: TransactionType): FinancingTypeDto => {
    switch (transactionType) {
      case TransactionType.CashPurchase:
        return FinancingTypeDto.CashPurchase;
      case TransactionType.Financed:
        return FinancingTypeDto.Financed;
      case TransactionType.Lease:
        return FinancingTypeDto.Lease;
      default:
        throw new Error('Invalid TransactionType : ' + transactionType);
    }
  };

  const onBuyerOrganizationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const organizationNumber = event.target.value;

    updateOrganizationNumberInput(organizationNumber);

    setBuyerSearchError(null);

    if (organizationNumber.length < 10) {
      return;
    }

    if (!Organisationsnummer.valid(organizationNumber)) {
      setBuyerSearchError('Ogiltigt organisationsnummer');
      return;
    }

    searchCompany(organizationNumber);
  };

  const updateOrganizationNumberInput = (organizationNumber: string) => {
    const formatedOrganizationNumber = Organisationsnummer.valid(organizationNumber)
      ? Organisationsnummer.parse(organizationNumber).format(true)
      : organizationNumber;
    setBuyerOrganizationNumberInput(formatedOrganizationNumber);
  };

  const searchCompany = async (organizationNumber: string) => {
    setBuyerSearchError(null);

    const formatedOrganizationNumber = Organisationsnummer.parse(organizationNumber).format(false);

    setBuyerOrganizationNumber(formatedOrganizationNumber);
  };

  const onBuyerSocialSecurityNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const socialSecurityNumber = event.target.value;
    updateSocialSecurityNumberInput(socialSecurityNumber);

    setBuyerSearchError(null);

    if (socialSecurityNumber.length < 10) {
      return;
    }

    if (!Personnummer.valid(socialSecurityNumber)) {
      setBuyerSearchError('Ogiltigt personnummer');
      return;
    }

    searchPerson(socialSecurityNumber);
  };

  const updateSocialSecurityNumberInput = (socialSecurityNumber: string) => {
    const formatedSocialSecurityNumber = Personnummer.valid(socialSecurityNumber)
      ? Personnummer.parse(socialSecurityNumber).format()
      : socialSecurityNumber;
    setBuyerSocialSecurityNumberInput(formatedSocialSecurityNumber);
  };

  const searchPerson = async (socialSecurityNumber: string) => {
    const formatedSocialSecurityNumber = getFormatedSocialSecurityNumberNumber(socialSecurityNumber);

    setBuyerSearchError(null);

    setBuyerSocialSecurityNumber(formatedSocialSecurityNumber);
  };

  const onOwnerTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyerOrganizationNumber('');
    setBuyerOrganizationNumberInput('');
    setBuyerSocialSecurityNumber('');
    setBuyerSocialSecurityNumberInput('');
    setOwnerType(parseInt(event.target.value) as OwnerTypeDto);
  };

  const submitChangeOfOwnership = async () => {
    if (!id) {
      return;
    }

    const newOwnerId = ownerType === OwnerTypeDto.Company ? buyerCompanyQuery.data?.id : buyerPersonQuery.data?.id;

    if (!newOwnerId) {
      return;
    }

    const financingType = getFinancingType(transactionType);

    const newOwnerChange: OwnerChangeDto = {
      registrationId: parseInt(id),
      newOwnerId,
      financierId: financier?.id,
      externalId,
      financingType,
    };

    ownerChange.mutate(newOwnerChange);
  };

  return (
    <>
      <Container>
        <BreadCrumbs />
        <PageContent>
          <PageTitle>Ägarbyte</PageTitle>

          <Typography variant="h5" gutterBottom>
            {registration?.name}
          </Typography>

          <Typography variant="h6" gutterBottom>
            {registration?.category}
          </Typography>

          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <StepperWrapper>
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step>
                    <StepLabel>Uppgifter om köpare</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                              Köpare
                            </Typography>
                            <FormInputWrapper>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={ownerType}
                                  onChange={onOwnerTypeChange}
                                >
                                  <FormControlLabel value={OwnerTypeDto.Company} control={<Radio />} label="Företag" />
                                  <FormControlLabel
                                    value={OwnerTypeDto.Person}
                                    control={<Radio />}
                                    label="Privatperson"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </FormInputWrapper>

                            {ownerType === OwnerTypeDto.Company && (
                              <FormInputWrapper>
                                <TextField
                                  onChange={onBuyerOrganizationNumberChange}
                                  value={buyerOrganizationNumberInput}
                                  label="Organisationsnummer"
                                  size="small"
                                  fullWidth
                                />
                              </FormInputWrapper>
                            )}
                            {ownerType === OwnerTypeDto.Person && (
                              <FormInputWrapper>
                                <TextField
                                  onChange={onBuyerSocialSecurityNumberChange}
                                  value={buyerSocialSecurityNumberInput}
                                  label="Personnummer"
                                  size="small"
                                  fullWidth
                                />
                              </FormInputWrapper>
                            )}
                            <Divider />
                            {buyerSearchError && (
                              <FormInputWrapper>
                                <Typography variant="body2" gutterBottom>
                                  {buyerSearchError}
                                </Typography>
                              </FormInputWrapper>
                            )}
                            {buyerPersonQuery.data && (
                              <>
                                <Typography variant="caption" gutterBottom>
                                  Privatperson
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                  {buyerPersonQuery.data.givenName} {buyerPersonQuery.data.familyName}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {getFormatedSocialSecurityNumberNumber(buyerPersonQuery.data.socialSecurityNumber)}
                                </Typography>
                              </>
                            )}
                            {buyerCompanyQuery.data && (
                              <>
                                <Typography variant="caption" gutterBottom>
                                  Företag/Organisation
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                  {buyerCompanyQuery.data.name}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {getFormatedOrganizationNumber(buyerCompanyQuery.data.organizationNumber)}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultButton onClick={() => setActiveStep(1)}>Nästa</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Finansiering</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <SelectFinancier
                          onExternalIdChange={(value) => setExternalId(value)}
                          onFinancierChange={(value) => setFinancier(value)}
                          onTransactionTypeChange={(value) => setTransactionType(value)}
                          selectedFinancierId={financier?.id}
                          transactionType={transactionType}
                          externalId={externalId}
                        />
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => setActiveStep(0)}>Tillbaka</DefaultTextButton>
                        <DefaultButton onClick={() => setActiveStep(2)}>Nästa</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Sammanställning</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom>
                              Nuvarande ägare
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                              {registration?.currentOwner?.fullName}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {registration?.currentOwner?.isPerson &&
                                getFormatedSocialSecurityNumberNumber(registration?.currentOwner?.pid)}

                              {!registration?.currentOwner?.isPerson &&
                                getFormatedOrganizationNumber(registration?.currentOwner?.pid)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom>
                              Blivande {transactionType === TransactionType.Lease ? 'brukare' : 'ägare'}
                            </Typography>
                            {ownerType === OwnerTypeDto.Person && (
                              <>
                                <Typography variant="h6" gutterBottom>
                                  {buyerPersonQuery.data?.givenName} {buyerPersonQuery.data?.familyName}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {getFormatedSocialSecurityNumberNumber(buyerPersonQuery.data?.socialSecurityNumber)}
                                </Typography>
                              </>
                            )}

                            {ownerType === OwnerTypeDto.Company && (
                              <>
                                <Typography variant="h6" gutterBottom>
                                  {buyerCompanyQuery.data?.name}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {getFormatedOrganizationNumber(buyerCompanyQuery.data?.organizationNumber)}
                                </Typography>
                              </>
                            )}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            {transactionType === TransactionType.CashPurchase && (
                              <>
                                <Typography variant="subtitle1" gutterBottom>
                                  Kontantköp
                                </Typography>
                              </>
                            )}
                            {transactionType === TransactionType.Lease && (
                              <>
                                <Typography variant="subtitle1" gutterBottom>
                                  Leasad från
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {financier?.name}
                                </Typography>
                                {externalId && (
                                  <Typography variant="body2" gutterBottom>
                                    Kontraktsnummer: {externalId}
                                  </Typography>
                                )}
                              </>
                            )}
                            {transactionType === TransactionType.Financed && (
                              <>
                                <Typography variant="subtitle1" gutterBottom>
                                  Finansierad av
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {financier?.name}
                                </Typography>
                                {externalId && (
                                  <Typography variant="body2" gutterBottom>
                                    Kontraktsnummer: {externalId}
                                  </Typography>
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => setActiveStep(1)}>Tillbaka</DefaultTextButton>
                        <DefaultButton onClick={() => submitChangeOfOwnership()}>
                          Genomför ansökan om ägarbyte
                        </DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>
                </Stepper>
              </StepperWrapper>
            </Grid>
          </Grid>
        </PageContent>
      </Container>
    </>
  );
};
