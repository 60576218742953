import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { getFinancingDetails } from '../../../api/protected/financings';
import { DefaultButton, DefaultTextButton, Divider, FormInputWrapper, LoadingWrapper } from '../../../layout';
import { FinancingActionType } from '../../../shared/types/financing';
import { FinancingUpdateDto } from '../../../shared/types/admin/financing';
import { getRegistrationUrl } from '../../../shared/utils/registration-url';

type EditFinancingDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  financingId?: number | null;
  onSave: (financingUpdate: FinancingUpdateDto) => void;
};

export const EditFinancingDialog = ({ isOpen, onClose, financingId, onSave }: EditFinancingDialogProps) => {
  const { data: financing, isLoading } = useQuery({
    queryKey: ['financing', financingId],
    queryFn: () => getFinancingDetails(financingId || 0),
    enabled: !!financingId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (financing) {
      setIsResolved(financing.resolvedAt !== null);
      setResolvedComment(financing.resolvedComment || '');
      setResolvedAt(financing.resolvedAt ? new Date(financing.resolvedAt) : null);
    }
  }, [financing]);

  const [isResolved, setIsResolved] = useState(false);

  const [resolvedComment, setResolvedComment] = useState('');

  const [resolvedAt, setResolvedAt] = useState<Date | null>(new Date());

  const mainRegistration = financing?.registrations.find((r) => r.parentId === null);
  const registrationUrl = getRegistrationUrl(mainRegistration);

  const isValid = () => {
    if (!isResolved) {
      return false;
    }

    if (isResolved && !resolvedAt) {
      return false;
    }

    return true;
  };

  const onSaveClick = () => {
    if (!isValid()) {
      return;
    }

    const financingUpdate: FinancingUpdateDto = {
      id: financingId || 0,
      resolvedAt: isResolved ? dayjs(resolvedAt).format('YYYY-DD-MM') : null,
      resolvedComment,
    };

    onSave(financingUpdate);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Registrering
          </Typography>
          {isLoading ? (
            <>
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption">{mainRegistration?.category}</Typography>
                  <Typography variant="subtitle2">
                    <Link target="_blank" to={registrationUrl}>
                      {mainRegistration?.name}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">Underregistreringar</Typography>
                  <Typography variant="subtitle2">
                    {financing?.registrations
                      .filter((r) => r.parentId !== null)
                      ?.map((r) => r.name)
                      .join(', ') || 'Inga underregistreringar'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">Nuvarande ägare</Typography>
                  <Typography variant="subtitle2">{financing?.registrations[0].currentOwner}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">Blivande ägare</Typography>
                  <Typography variant="subtitle2">{financing?.registrations[0].nextOwner}</Typography>
                </Grid>
              </Grid>

              <Divider />

              <Typography variant="h6" gutterBottom>
                Finansiering
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption">Kreditgivare</Typography>
                  <Typography variant="subtitle2">{financing?.financier.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">instituteId</Typography>
                  <Typography variant="subtitle2">{financing?.financier.instituteId}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">organizationNumber</Typography>
                  <Typography variant="subtitle2">{financing?.financier.organizationNumber}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">lei</Typography>
                  <Typography variant="subtitle2">{financing?.financier.lei}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">bankType</Typography>
                  <Typography variant="subtitle2">{financing?.financier.bankType}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">address</Typography>
                  <Typography variant="subtitle2">{financing?.financier.address}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">postalCode zip</Typography>
                  <Typography variant="subtitle2">
                    {financing?.financier.zipCode} {financing?.financier.city}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={2}>
                {financing?.actionRequired === FinancingActionType.Resolve && (
                  <Grid item xs={6}>
                    <FormInputWrapper>
                      <FormControlLabel
                        control={<Checkbox checked={isResolved} onChange={(e) => setIsResolved(e.target.checked)} />}
                        label="Finansiering löst"
                      />
                    </FormInputWrapper>

                    <FormInputWrapper>
                      <DatePicker
                        disabled={!isResolved}
                        label="Datum"
                        value={resolvedAt}
                        onChange={(newValue) => {
                          setResolvedAt(newValue);
                        }}
                        renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </FormInputWrapper>

                    <FormInputWrapper>
                      <TextField
                        disabled={!isResolved}
                        label="Kommentar"
                        value={resolvedComment}
                        onChange={(e) => setResolvedComment(e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </FormInputWrapper>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={isLoading || !isValid()} onClick={onSaveClick}>
            Spara
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
