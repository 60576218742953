import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';
import { FinancingUpdateDto, GetFinancingsResponse } from './../../shared/types/admin/financing';

export const useFinancingsQuery = (filter: string, page: number, rowsPerPage: number, actionRequired: boolean) => {
  return useQuery({
    queryKey: ['financings', filter, page, rowsPerPage, actionRequired],
    queryFn: async () => {
      const response = await apiRequest2<GetFinancingsResponse>({
        action: `Admin/Financings`,
        method: 'GET',
        allowAnonymous: false,
        params: { filter, page, rowsPerPage, actionRequired },
      });

      return response.data;
    },
  });
};

export const useUpdateFinancingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (financingUpdate: FinancingUpdateDto) => {
      const response = await apiRequest2<void, FinancingUpdateDto>({
        action: `Admin/Financings/${financingUpdate.id}`,
        method: 'PUT',
        body: financingUpdate,
        allowAnonymous: false,
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['financings'] });
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
};

export const useDeleteFinancingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      await apiRequest2<void, void>({
        action: `Admin/Financings/${id}`,
        method: 'DELETE',
        allowAnonymous: false,
      });

      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['financings'] });
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
};
