import { Container } from '@mui/material';
import { PageContent, PageTitle } from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';
import { InsuranceList } from '../../components/insurances/InsuranceList';

export const InsurancesPage = () => {
  return (
    <Container>
      <BreadCrumbs />
      <PageContent>
        <PageTitle>Försäkringar</PageTitle>
        <InsuranceList />
      </PageContent>
    </Container>
  );
};
