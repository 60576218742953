import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../context/UserProvider';
import { paths } from '../../navigation/paths';

export const ProtectedRoutes = () => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) {
    return <Navigate to={paths.signIn.path} replace />;
  }

  return <Outlet />;
};
